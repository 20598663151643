import { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { getMeterDataOptions } from "@apis/resource.queries";
import { useNetworkStore } from "@core";
import { MeterDataComponent } from "@models/meter-data.model";
import apiStore from "@stores/api.store";
import { BaseCard, HeadingBar, HighchartsBase, InfoCard } from "@shared";
import { Section } from "@shared/ui/layout";

// Import components for MeterChart and ControlPanel
import { ControlPanel, MeterChart } from "../../components/EnergyConsumption";

export function PropertyDetailEnergyData() {
  const fetchedRange = useNetworkStore((s) => s.fetchedRange);
  const selectedSubstationId = useNetworkStore((s) => s.selectedSubstationId);
  const theme = useTheme();

  const { data: rawMeterData, isLoading: meterDataLoading } = useQuery(
    getMeterDataOptions(
      apiStore.get.apis(),
      selectedSubstationId,
      ["heat_energy", "returntemp", "supplytemp"],
      fetchedRange.start,
      fetchedRange.end
    )
  );

  // State for ControlPanel
  const [isPanelOpen, setIsPanelOpen] = useState(true);

  const [resolution, setResolution] = useState("hourly");
  const [graphSettings, setGraphSettings] = useState({
    average: false,
    min: false,
    max: false,
    grid: false,
  });

  const formattedMeterData = useMemo(() => {
    if (!rawMeterData || rawMeterData.length === 0) {
      console.log("No meter data available");
      return [];
    }

    return rawMeterData.map((component: MeterDataComponent) => {
      const sortedData = [...component.data].sort(
        (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
      );

      return {
        uid: component.resource_uid,
        meter: component.meter_name,
        stage: component.stage_name,
        component: component.component_name,
        variant: component.variant_name,
        data: sortedData.map((point) => ({
          datetime: point.datetime,
          value: point.value,
          flags: [],
        })),
      };
    });
  }, [rawMeterData]);

  // Handle control panel changes
  const handlePanelToggle = (isOpen: boolean) => {
    setIsPanelOpen(isOpen);
  };

  const handleDateRangeChange = (startDate: DateTime | null, endDate: DateTime | null) => {
    useNetworkStore.setState({
      fetchedRange: {
        start: startDate ?? fetchedRange.start,
        end: endDate ?? fetchedRange.end,
      },
    });
  };

  const handleResolutionChange = (newResolution: string) => {
    setResolution(newResolution);
  };

  const handleGraphSettingsChange = (settings: typeof graphSettings) => {
    setGraphSettings(settings);
  };

  return (
    <Box width="100%" py={2}>
      <Box sx={{ display: "flex", width: "100%" }}>
        {/* Control Panel Sidebar */}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            height: "100vh",
            flexShrink: 0,
            zIndex: 10,
            alignSelf: "flex-start",
            bgcolor: "background.paper",
          }}
        >
          <ControlPanel
            isOpen={isPanelOpen}
            onToggle={handlePanelToggle}
            onDateRangeChange={handleDateRangeChange}
            onResolutionChange={handleResolutionChange}
            onGraphSettingsChange={handleGraphSettingsChange}
            initialDateRange={{ startDate: fetchedRange.startDate, endDate: fetchedRange.endDate }}
          />
        </Box>

        <Box
          sx={{
            transition: "padding 0.3s ease",
          }}
          px={2}
          width="100%"
        >
          <Grid
            container
            spacing={2}
            flexDirection="column"
            alignItems="stretch"
            justifyContent="flex-start"
            flexGrow={1}
            width="100%"
          >
            <Grid item xs={12} height={600}>
              <HeadingBar title="Energy Consumption" />
              <Grid container columnSpacing={1} my={2} flexDirection="row" height={300}>
                <Grid item xs>
                  <InfoCard
                    title="Total Consumption "
                    value={160000}
                    unit="kWh"
                    subtitle="Last 12 months"
                  />
                </Grid>
                <Grid item xs>
                  <BaseCard title="Last 12 Months">
                    {/* fake bar chart */}
                    <HighchartsBase
                      chart={{
                        height: 200,
                        type: "column",
                        zoomType: "",
                        backgroundColor: "white",
                      }}
                      customMenu={[]}
                      series={{
                        // High in the beginning, and in the end
                        data: [120, 110, 100, 90, 80, 85, 95, 100, 110, 120, 130, 140],
                        color: theme.palette.primary.secondary,
                        name: "Energy Consumption",
                      }}
                      seriesOptions={{ marker: { enabled: false } }}
                      height={200}
                      width="100%"
                      legend={{ enabled: false }}
                      xAxis={{
                        categories: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                        labels: {
                          formatter() {
                            return this.value;
                          },
                        },
                      }}
                      yAxis={{
                        title: {
                          text: "Consumption",
                        },
                        yUnit: "MWh",
                      }}
                      tooltip={{
                        formatter() {
                          return `<b>${this.x}</b>: ${this.y} MWh`;
                        },
                      }}
                    />
                  </BaseCard>
                </Grid>
                <Grid item xs>
                  <BaseCard title="Last 5 Years">
                    <HighchartsBase
                      chart={{
                        height: 200,
                        type: "column",
                        zoomType: "",
                        backgroundColor: "white",
                      }}
                      customMenu={[]}
                      series={{
                        // High in the beginning, and in the end
                        data: [120, 110, 120, 130, 140],
                        color: theme.palette.primary.secondary,
                        name: "Energy Consumption",
                      }}
                      seriesOptions={{ marker: { enabled: false } }}
                      height={200}
                      width="100%"
                      legend={{ enabled: false }}
                      xAxis={{
                        categories: ["2020", "2021", "2022", "2023", "2024", "2025"],
                        labels: {
                          formatter() {
                            return this.value;
                          },
                        },
                      }}
                      yAxis={{
                        title: {
                          text: "Consumption",
                        },
                        yUnit: "MWh",
                      }}
                      tooltip={{
                        formatter() {
                          return `<b>${this.x}</b>: ${this.y} MWh`;
                        },
                      }}
                    />
                  </BaseCard>
                </Grid>
              </Grid>
            </Grid>

            {/* Meter Chart */}
            <Grid item xs={12}>
              <MeterChart
                data={formattedMeterData}
                isLoading={meterDataLoading}
                dateRange={{ startDate: fetchedRange.start, endDate: fetchedRange.end }}
                resolution={resolution}
                graphSettings={graphSettings}
              />
            </Grid>
          </Grid>
          {/* I dont have time for this, so I will just comment it out for now */}
          {/* <FilterBar
            data={
              formattedMeterData.find((component) => component.component === "heat_energy")?.data ||
              []
            }
            range={fetchedRange}
            registerChart={registerChart}
          /> */}
        </Box>
      </Box>
    </Box>
  );
}
