import { QueryClient } from "@tanstack/react-query";
import axios, { AxiosInstance } from "axios";
import { createStore } from "zustand-x";

import { customAxiosDefaults } from "@apis/apiClient";
import { ResourceApi } from "@apis/resource.api";
import { WeatherApi } from "@apis/weather.api";
import authStore from "@core/stores/auth.store";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
    },
  },
});

type ApiStore = {
  resourceApi: ResourceApi | null;
  weatherApi: WeatherApi | null;
  axiosClient: AxiosInstance | null;
};

export type AvailableApis = {
  resourceApi: ResourceApi;
  weatherApi: WeatherApi;
};

const apiStore = createStore<ApiStore>(
  {
    resourceApi: null,
    weatherApi: null,
    axiosClient: null,
  },
  {
    name: "RootStore",
  }
)
  .extendSelectors((set, get) => ({
    apis: () => {
      const apis: Partial<AvailableApis> = {};
      for (const key of Object.keys(get.state())) {
        if (key.endsWith("Api")) {
          //@ts-expect-error - this is a workaround for the type error
          apis[key as keyof AvailableApis] = get.state()[key];
        }
      }
      return apis as AvailableApis;
    },
  }))
  // Actions are for setting state
  .extendActions((set) => ({
    initializeApis: (store: typeof authStore) => {
      const token = store.get.token();
      const client = axios.create(customAxiosDefaults);
      // Add the authorization header to all requests
      client.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      set.axiosClient(client);

      if (!token) return;
      set.resourceApi(new ResourceApi(client));
      set.weatherApi(new WeatherApi(client));
    },
    resetApi: () => {
      set.resourceApi(null);
      set.weatherApi(null);
      set.axiosClient(null);
    },
  }));

export default apiStore; // updated to export the correct variable
