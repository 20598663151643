import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Box, useTheme } from "@mui/system";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts from "highcharts/highstock";
import HighchartsBoost from "highcharts/modules/boost";
import { DateTime } from "luxon";

import { BaseCard } from "@shared";

// Initialize Highcharts modules
if (typeof Highcharts === "object") {
  HighchartsBoost(Highcharts);
  HighchartsMore(Highcharts);
}

enum ComponentType {
  HEAT_ENERGY = "heat_energy",
  SUPPLY_TEMP = "supplytemp",
  RETURN_TEMP = "returntemp",
}

// Keep original colors to maintain consistency in data visualization
const COLORS = {
  HEAT_ENERGY: "#607c82",
  SUPPLY_TEMP: "#dd3b00",
  RETURN_TEMP: "#de9c7f",
};

const UNITS = {
  HEAT_ENERGY: " MWh",
  TEMPERATURE: " °C",
};

const CHART_DEFAULTS = {
  LEGEND_HEIGHT: 42,
  BOTTOM_MARGIN: 88,
  DEFAULT_HEIGHT: 550,
};

type DataPoint = {
  datetime: string;
  value: number;
  flags: string[];
};

type MeterData = {
  uid: string;
  meter: string;
  stage: string;
  component: string;
  variant: string;
  data: DataPoint[];
};

// Graph settings from control panel
type GraphSettings = {
  average: boolean;
  min: boolean;
  max: boolean;
  grid: boolean;
};

// Extended props to include control panel settings
type MeterChartProps = {
  data: MeterData[];
  isLoading?: boolean;
  chartHeight?: number;
  // Control panel settings
  dateRange?: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  };
  resolution?: string;
  graphSettings?: GraphSettings;
};

type SeriesVisibilityState = {
  heatEnergy: boolean;
  supplyTemp: boolean;
  returnTemp: boolean;
};

type SeriesKey = keyof SeriesVisibilityState;

const createSeriesData = (data: DataPoint[]): [number, number][] => {
  return data.map((point) => [DateTime.fromISO(point.datetime).toMillis(), point.value]);
};

const wouldHideAllSeries = (
  currentVisibility: SeriesVisibilityState,
  seriesName: string
): boolean => {
  const newVisibility = { ...currentVisibility };

  if (seriesName === "Heat Energy") newVisibility.heatEnergy = !currentVisibility.heatEnergy;
  else if (seriesName === "Supply Temperature")
    newVisibility.supplyTemp = !currentVisibility.supplyTemp;
  else if (seriesName === "Return Temperature")
    newVisibility.returnTemp = !currentVisibility.returnTemp;

  return !newVisibility.heatEnergy && !newVisibility.supplyTemp && !newVisibility.returnTemp;
};

// Filter data based on date range
const filterDataByDateRange = (
  data: DataPoint[],
  startDate: DateTime | null,
  endDate: DateTime | null
): DataPoint[] => {
  if (!startDate && !endDate) return data;

  return data.filter((point) => {
    const pointDate = DateTime.fromISO(point.datetime);
    if (startDate && pointDate < startDate) return false;
    if (endDate && pointDate > endDate) return false;
    return true;
  });
};

// Custom hook for chart options
const useChartOptions = (
  data: MeterData[],
  chartHeight: number,
  seriesVisibility: SeriesVisibilityState,
  onSeriesToggle: (seriesName: string) => void,
  dateRange: { startDate: DateTime | null; endDate: DateTime | null },
  resolution: string,
  graphSettings: GraphSettings,
  theme: any
): Highcharts.Options => {
  return useMemo(() => {
    // Filter raw data by date range
    const findAndFilterData = (componentType: ComponentType) => {
      const seriesData = data.find((d) => d.component === componentType)?.data || [];
      return filterDataByDateRange(seriesData, dateRange.startDate, dateRange.endDate);
    };

    const heatEnergyData = findAndFilterData(ComponentType.HEAT_ENERGY);
    const supplyTempData = findAndFilterData(ComponentType.SUPPLY_TEMP);
    const returnTempData = findAndFilterData(ComponentType.RETURN_TEMP);

    const yAxis = getYAxisConfigurations(seriesVisibility, chartHeight, graphSettings.grid, theme);

    const series = getSeriesConfigurations(
      heatEnergyData,
      supplyTempData,
      returnTempData,
      seriesVisibility,
      graphSettings
    );

    // Set data grouping based on resolution
    const dataGrouping = {
      enabled: true,
      units:
        resolution === "hourly"
          ? [
              ["hour", [1, 3, 6, 12]],
              ["day", [1]],
            ]
          : [
              ["day", [1]],
              ["week", [1]],
              ["month", [1, 3, 6]],
            ],
      approximation: graphSettings.average ? "average" : "high",
      groupPixelWidth: 100,
    };

    return {
      chart: {
        height: chartHeight,
        zoomType: "x",
        type: "line",
        animation: false,
        spacing: [10, 30, 15, 10],
        backgroundColor: "transparent",
      },
      boost: {
        useGPUTranslations: true,
        usePreallocated: true,
      },
      xAxis: {
        type: "datetime",
        ordinal: false,
        labels: {
          format: resolution === "hourly" ? "{value:%Y-%m-%d %H:%M}" : "{value:%Y-%m-%d}",
        },
        min: dateRange.startDate ? dateRange.startDate.toMillis() : undefined,
        max: dateRange.endDate ? dateRange.endDate.toMillis() : undefined,
        minRange: resolution === "hourly" ? undefined : 24 * 3600 * 1000,
        gridLineWidth: graphSettings.grid ? 1 : 0,
        lineColor: theme.palette?.divider,
        tickColor: theme.palette?.divider,
        lineWidth: 1,
      },
      yAxis,
      legend: {
        enabled: true,
        align: "left",
        verticalAlign: "top",
        layout: "horizontal",
      },
      time: {
        timezone: "Europe/Oslo",
        useUTC: false,
      },
      plotOptions: {
        series: {
          marker: { enabled: false },
          states: {
            hover: {
              enabled: true,
              lineWidth: 3,
              halo: {
                size: 5,
              },
            },
          },
          dataGrouping,
          showInLegend: true,
          events: {
            legendItemClick: function (e) {
              e.preventDefault();
              onSeriesToggle(this.name);
              return false;
            },
          },
        },
      },
      series,
      tooltip: {
        shared: true,
        split: false,
        formatter: function () {
          if (!this.points) return "";
          const dateFormat = resolution === "hourly" ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd";
          const date = DateTime.fromMillis(this.x).setZone("Europe/Oslo").toFormat(dateFormat);
          let html = `<b>${date}</b><br/>`;
          this.points.forEach((point) => {
            html += `<span style="color:${point.series.color}">\u25CF</span> ${
              point.series.name
            }: <b>${point.y.toFixed(2)}${point.series.tooltipOptions.valueSuffix}</b><br/>`;
          });
          return html;
        },
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: { enabled: false, height: 30, margin: 20 },
      scrollbar: {
        enabled: true,
        height: 8,
      },
      credits: { enabled: false },
    };
  }, [
    data,
    chartHeight,
    seriesVisibility,
    onSeriesToggle,
    dateRange,
    resolution,
    graphSettings,
    theme,
  ]);
};

const getYAxisConfigurations = (
  seriesVisibility: SeriesVisibilityState,
  chartHeight: number,
  showGrid: boolean,
  theme: any
): Highcharts.YAxisOptions[] => {
  const visibleSeries: SeriesKey[] = [];
  if (seriesVisibility.heatEnergy) visibleSeries.push("heatEnergy");
  if (seriesVisibility.supplyTemp) visibleSeries.push("supplyTemp");
  if (seriesVisibility.returnTemp) visibleSeries.push("returnTemp");

  const seriesCount = visibleSeries.length;
  const availableHeight = chartHeight - CHART_DEFAULTS.LEGEND_HEIGHT - CHART_DEFAULTS.BOTTOM_MARGIN;
  const seriesHeight = availableHeight / Math.max(seriesCount, 1);

  const yAxisConfig: Record<SeriesKey, Omit<Highcharts.YAxisOptions, "top">> = {
    heatEnergy: {
      title: {
        text: "Heat Energy (MWh)",
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(2);
        },
      },
      height: seriesHeight,
      offset: 0,
      gridLineWidth: showGrid ? 1 : 0,
      gridLineColor: theme.palette?.divider,
      opposite: false,
    },
    supplyTemp: {
      title: {
        text: "Supply Temperature (°C)",
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(2);
        },
      },
      height: seriesHeight,
      offset: 0,
      gridLineWidth: showGrid ? 1 : 0,
      gridLineColor: theme.palette?.divider,
      opposite: false,
    },
    returnTemp: {
      title: {
        text: "Return Temperature (°C)",
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(2);
        },
      },
      height: seriesHeight,
      offset: 0,
      gridLineWidth: showGrid ? 1 : 0,
      gridLineColor: theme.palette?.divider,
      opposite: false,
    },
  };

  // fallback for if somehow no series is selected
  if (seriesCount === 0) {
    return [
      {
        title: { text: "No data selected" },
        labels: { enabled: false },
        height: availableHeight,
        top: CHART_DEFAULTS.LEGEND_HEIGHT,
        offset: 0,
        gridLineWidth: 0,
      },
    ];
  }

  // Calculate positions and return visible axes only
  let currentOffset = CHART_DEFAULTS.LEGEND_HEIGHT;
  return visibleSeries.map((seriesKey) => {
    const config = {
      ...yAxisConfig[seriesKey],
      top: currentOffset,
      lineWidth: 1,
      lineColor: theme.palette?.divider,
    };
    currentOffset += seriesHeight;
    return config;
  });
};

const getSeriesConfigurations = (
  heatEnergyData: DataPoint[],
  supplyTempData: DataPoint[],
  returnTempData: DataPoint[],
  seriesVisibility: SeriesVisibilityState,
  graphSettings: GraphSettings
): Highcharts.SeriesOptionsType[] => {
  // Calculate yAxis index for each visible series
  const visibleAxes: SeriesKey[] = [];
  if (seriesVisibility.heatEnergy) visibleAxes.push("heatEnergy");
  if (seriesVisibility.supplyTemp) visibleAxes.push("supplyTemp");
  if (seriesVisibility.returnTemp) visibleAxes.push("returnTemp");

  const getYAxisIndex = (seriesKey: SeriesKey): number | undefined => {
    const index = visibleAxes.indexOf(seriesKey);
    return index !== -1 ? index : undefined;
  };

  const baseSeries = [
    {
      name: "Heat Energy",
      type: "line",
      data: createSeriesData(heatEnergyData),
      tooltip: { valueSuffix: UNITS.HEAT_ENERGY },
      color: COLORS.HEAT_ENERGY,
      yAxis: getYAxisIndex("heatEnergy"),
      visible: seriesVisibility.heatEnergy,
      showInLegend: true,
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
      marker: {
        enabled: false,
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    {
      name: "Supply Temperature",
      type: "line",
      data: createSeriesData(supplyTempData),
      tooltip: { valueSuffix: UNITS.TEMPERATURE },
      color: COLORS.SUPPLY_TEMP,
      yAxis: getYAxisIndex("supplyTemp"),
      visible: seriesVisibility.supplyTemp,
      showInLegend: true,
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
      marker: {
        enabled: false,
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    {
      name: "Return Temperature",
      type: "line",
      data: createSeriesData(returnTempData),
      tooltip: { valueSuffix: UNITS.TEMPERATURE },
      color: COLORS.RETURN_TEMP,
      yAxis: getYAxisIndex("returnTemp"),
      visible: seriesVisibility.returnTemp,
      showInLegend: true,
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
      marker: {
        enabled: false,
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  ];

  const additionalSeries = [];

  // Add min series if enabled
  if (graphSettings.min) {
    if (seriesVisibility.heatEnergy && heatEnergyData.length > 0) {
      additionalSeries.push({
        name: "Min Heat Energy",
        type: "line",
        data: createSeriesData(heatEnergyData),
        tooltip: { valueSuffix: UNITS.HEAT_ENERGY },
        color: COLORS.HEAT_ENERGY,
        yAxis: getYAxisIndex("heatEnergy"),
        visible: true,
        showInLegend: false,
        dashStyle: "Dash",
        opacity: 0.5,
        dataGrouping: {
          approximation: "low",
        },
      });
    }

    if (seriesVisibility.supplyTemp && supplyTempData.length > 0) {
      additionalSeries.push({
        name: "Min Supply Temp",
        type: "line",
        data: createSeriesData(supplyTempData),
        tooltip: { valueSuffix: UNITS.TEMPERATURE },
        color: COLORS.SUPPLY_TEMP,
        yAxis: getYAxisIndex("supplyTemp"),
        visible: true,
        showInLegend: false,
        dashStyle: "Dash",
        opacity: 0.5,
        dataGrouping: {
          approximation: "low",
        },
      });
    }

    if (seriesVisibility.returnTemp && returnTempData.length > 0) {
      additionalSeries.push({
        name: "Min Return Temp",
        type: "line",
        data: createSeriesData(returnTempData),
        tooltip: { valueSuffix: UNITS.TEMPERATURE },
        color: COLORS.RETURN_TEMP,
        yAxis: getYAxisIndex("returnTemp"),
        visible: true,
        showInLegend: false,
        dashStyle: "Dash",
        opacity: 0.5,
        dataGrouping: {
          approximation: "low",
        },
      });
    }
  }

  // Add max series if enabled
  if (graphSettings.max) {
    if (seriesVisibility.heatEnergy && heatEnergyData.length > 0) {
      additionalSeries.push({
        name: "Max Heat Energy",
        type: "line",
        data: createSeriesData(heatEnergyData),
        tooltip: { valueSuffix: UNITS.HEAT_ENERGY },
        color: COLORS.HEAT_ENERGY,
        yAxis: getYAxisIndex("heatEnergy"),
        visible: true,
        showInLegend: false,
        dashStyle: "DashDot",
        opacity: 0.5,
        dataGrouping: {
          approximation: "high",
        },
      });
    }

    if (seriesVisibility.supplyTemp && supplyTempData.length > 0) {
      additionalSeries.push({
        name: "Max Supply Temp",
        type: "line",
        data: createSeriesData(supplyTempData),
        tooltip: { valueSuffix: UNITS.TEMPERATURE },
        color: COLORS.SUPPLY_TEMP,
        yAxis: getYAxisIndex("supplyTemp"),
        visible: true,
        showInLegend: false,
        dashStyle: "DashDot",
        opacity: 0.5,
        dataGrouping: {
          approximation: "high",
        },
      });
    }

    if (seriesVisibility.returnTemp && returnTempData.length > 0) {
      additionalSeries.push({
        name: "Max Return Temp",
        type: "line",
        data: createSeriesData(returnTempData),
        tooltip: { valueSuffix: UNITS.TEMPERATURE },
        color: COLORS.RETURN_TEMP,
        yAxis: getYAxisIndex("returnTemp"),
        visible: true,
        showInLegend: false,
        dashStyle: "DashDot",
        opacity: 0.5,
        dataGrouping: {
          approximation: "high",
        },
      });
    }
  }

  return [...baseSeries, ...additionalSeries];
};

const MeterChart: React.FC<MeterChartProps> = ({
  data,
  isLoading = false,
  chartHeight = CHART_DEFAULTS.DEFAULT_HEIGHT,
  dateRange = { startDate: null, endDate: null },
  resolution = "hourly",
  graphSettings = { average: true, min: false, max: false, grid: false },
}) => {
  const theme = useTheme();
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  // track visibility of each series
  const [seriesVisibility, setSeriesVisibility] = React.useState<SeriesVisibilityState>({
    heatEnergy: true,
    supplyTemp: true,
    returnTemp: true,
  });

  // prevent unnecessary rerenders
  const handleSeriesToggle = useCallback(
    (seriesName: string): void => {
      if (wouldHideAllSeries(seriesVisibility, seriesName)) {
        return;
      }

      setSeriesVisibility((prev) => ({
        ...prev,
        heatEnergy: seriesName === "Heat Energy" ? !prev.heatEnergy : prev.heatEnergy,
        supplyTemp: seriesName === "Supply Temperature" ? !prev.supplyTemp : prev.supplyTemp,
        returnTemp: seriesName === "Return Temperature" ? !prev.returnTemp : prev.returnTemp,
      }));
    },
    [seriesVisibility]
  );

  // Get chart options using custom hook
  const chartOptions = useChartOptions(
    data,
    chartHeight,
    seriesVisibility,
    handleSeriesToggle,
    dateRange,
    resolution,
    graphSettings,
    theme
  );

  // Update chart extremes when date range changes
  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      const min = dateRange.startDate ? dateRange.startDate.toMillis() : undefined;
      const max = dateRange.endDate ? dateRange.endDate.toMillis() : undefined;
      chart.xAxis[0].setExtremes(min, max);
    }
  }, [dateRange.startDate, dateRange.endDate]);

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>Loading...</span>
      </Box>
    );
  }

  const hasData = data.some((item) => item.data && item.data.length > 0);
  if (!hasData) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>No data available for the selected period</span>
      </Box>
    );
  }

  return (
    <BaseCard title="Meter Data">
      <Box sx={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          options={chartOptions}
          ref={chartRef}
          containerProps={{ style: { width: "100%", height: "100%" } }}
        />
      </Box>
    </BaseCard>
  );
};

export default MeterChart;
