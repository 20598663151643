import { acceptInviteOptions } from '@apis/resource.queries';
import { Alert, AlertTitle, Box, Button, LinearProgress, Typography } from '@mui/material'
import apiStore from '@stores/api.store';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router'
import { useMemo } from 'react'
const regex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}:[A-Za-z0-9]{8}:[A-Za-z0-9\-_]{32}/

const ActivateAccountPage = () => {
  const { activationCode } = Route.useParams();

  const { data: acceptInvitation, error, isLoading } = useQuery(
    {
      ...acceptInviteOptions(apiStore.get.apis(), activationCode),
      enabled: activationCode.match(
        regex,
      ) !== null,
    }
  );

  const isValidCode = useMemo(() => {
    return activationCode.match(regex)}, [activationCode])

  return (
    <Box p={2} gap={2} display='flex' flexDirection='column'>
      <Typography variant='h2'>Activate your Account</Typography>
      <Box px={4}>
        {
          (error || !isValidCode) ?
          <Alert severity='error'>
              <AlertTitle>Failed to activate account. Please try again.</AlertTitle>
              {!isValidCode && <p>Invalid activation code.</p>}
              {error && <p>{error.message}</p>}
          </Alert>
        : acceptInvitation ?
          <Box display='flex' flexDirection='column' gap={2}>
            <Alert severity='success'>
              <AlertTitle>Account activated successfully!</AlertTitle>
            </Alert>
            <Button component={Link} to='/app' variant='contained' size="large" color="secondary">Start Exploring!</Button>
          </Box>
          : isLoading ? 
          <Box display='flex' flexDirection='column' gap={2}>
            <Typography variant="body1">Please wait while we get things ready...</Typography>
            <LinearProgress />
          </Box>
          : null
        }
      </Box>
    </Box>
  )
}

export const Route = createFileRoute(
  '/app/_layout/activate-account/$activationCode',
)({
  staticData: {
    pageTitle: 'Accept Account Invitation',
  },
  component: ActivateAccountPage,
})
