import { Box, Checkbox, Typography } from "@mui/material";

import { Sensor } from "@models/sensors.model";

type SensorItemProps = {
  sensor: Sensor;
  selectedSensors: string[];
  setSelectedSensors: (uids: string[]) => void;
};

const SensorCheckbox: React.FC<SensorItemProps> = ({
  sensor,
  selectedSensors,
  setSelectedSensors,
}) => {
  const handleChange = () => {
    if (selectedSensors.includes(sensor.uid)) {
      setSelectedSensors(selectedSensors.filter((uid) => uid !== sensor.uid));
    } else {
      setSelectedSensors([...selectedSensors, sensor.uid]);
    }
  };
  return (
    <Box
      key={sensor.uid}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
      }}
    >
      <Typography variant="body2">{sensor.external_id}</Typography>
      <Checkbox
        size="small"
        color="primary"
        checked={selectedSensors.includes(sensor.uid)}
        onChange={handleChange}
        disableRipple
      />
    </Box>
  );
};

export default SensorCheckbox;
