import { useEffect } from "react";
import { createFileRoute } from "@tanstack/react-router";

import { useNetworkStore } from "@core";
import { PropertyDetailModule } from "@modules/PropertyDetail/PropertyDetail.module";

const PropertyDetailPage = () => {
  const { substationId } = Route.useParams();
  const setSelectedSubstationId = useNetworkStore((s) => s.setSelectedSubstationId);

  useEffect(() => {
    // Set the selected substation ID in the network store
    setSelectedSubstationId(substationId);

    // Cleanup when component unmounts
    return () => {
      setSelectedSubstationId(undefined);
    };
  }, [substationId, setSelectedSubstationId]);

  return <PropertyDetailModule />;
};

export const Route = createFileRoute("/app/properties/$substationId")({
  staticData: {
    pageTitle: "Property Details",
  },
  component: PropertyDetailPage,
});
