import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Point, Series } from "highcharts";
import { DateTime } from "luxon";

import { DAY_MILIS, HOUR_MILIS } from "@config";
import { indoorClimateColumns } from "@config/consumption";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { Resolution } from "@components/EnergyConsumption/ControlPanel";
import { Sensor, SensorReading } from "@models/sensors.model";
import { BaseCard, HighchartsBase } from "@shared";

type DateRange = {
  startDate: DateTime | null;
  endDate: DateTime | null;
};

function transformSensorsData(sensors: SensorReading[]) {
  return sensors.map((item) => ({
    ts: DateTime.fromISO(item.timestamp).toMillis(),
    humid: item.values?.indoor_relative_humidity,
    temp: item.values?.living_air_temperature,
  }));
}

type IndoorClimateGraphProps = {
  sensors: Sensor[];
  selectedSensors: string[];
  resolution: Resolution;
  dateRange: DateRange;
  visibleSeries: Record<string, boolean>;
};

const IndoorClimateGraph = ({
  selectedSensors,
  sensors,
  resolution,
  dateRange,
  visibleSeries,
}: IndoorClimateGraphProps) => {
  const { t } = useTranslation();

  // Transform the sensors data to Highcharts format
  const transformedSensors = useMemo(() => {
    if (!sensors) return [];
    return sensors.map((sensor) => {
      return {
        ...sensor,
        data: transformSensorsData(sensor.data ?? []),
      };
    });
  }, [sensors]);

  const filteredSensors = useMemo(() => {
    if (!transformedSensors) return [];
    const filtered = transformedSensors.filter((sensor) => {
      return selectedSensors.includes(sensor.uid);
    });
    return filtered.flat();
  }, [selectedSensors, transformedSensors]);

  const { series, yAxis } = useMemo(() => {
    const seriesArr: Series[] = [];
    const yAxisArr = [];

    let yAxisSeries = null;

    const visibleTempSeries = Object.keys(visibleSeries).reduce((prev, current) => {
      if (!current.startsWith("temp")) return prev;
      if (visibleSeries[current]) return prev + 1;
      return prev;
    }, 0);

    if (visibleTempSeries) {
      yAxisArr.push({
        visible: true,
        lineWidth: 1,
        opposite: false,
        title: {
          useHTML: true,
          text: `<span>Temperature</span>`,
        },
        yUnit: "°C",
        labels: {
          align: "right",
          x: -15,
        },
      });
    }

    indoorClimateColumns
      .filter((column) => column.showInLegend)
      .forEach(({ key, ...col }) => {
        const type = "line";
        const color = "";
        const chartColor = "";
        const visible = visibleSeries[key];
        const name = `${col.name}`;
        let yAxisIndex = yAxisArr.length;

        if (key.endsWith("temp")) yAxisIndex = 0;

        if (visible) {
          filteredSensors.forEach((sensor, sensorIndex) => {
            const sensorData: Point[] = sensor.data?.map((s) => ({
              x: s.ts,
              y: s[key],
            }));

            // Generate a consistent color hue for the same sensor
            const uidHash = sensor.uid.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
            const baseHue = (uidHash % 360); // Ensure hue is within 0-359
            const sensorColor = `hsl(${baseHue}, 70%, 50%)`;

            seriesArr.push({
              id: `${sensor.uid}-${key}`,
              type,
              name: `${sensor.external_id} ${t(name)}`,
              data: sensorData,
              timeStamp: sensorData.length ? sensorData[0].x : 0,
              color: sensorColor,
              chartColor: sensorColor,
              visible,
              tooltip: {
                valueSuffix: col.suffix,
              },
              yAxis: yAxisIndex,
              allowPointSelect: true,
              marker: {
                fillColor: sensorColor,
                radius: 3,
                symbol: "circle",
              },
              showInLegend: col.showInLegend,
            });
          });
        }

        yAxisSeries = {
          visible,
          labels: {
            align: "right",
            x: -15,
            color,
          },
          chartColor: undefined,
          lineWidth: 1,
          resize: {
            enabled: true,
          },
          title: {
            useHTML: true,
            text: `<span>Humidity</span>`,
          },
          yUnit: col.suffix,
        };

        if (!key.startsWith("temp")) yAxisArr.push(yAxisSeries);
      });

    return { series: seriesArr, yAxis: yAxisArr };
  }, [filteredSensors, t, visibleSeries]);

  const plotOptions = useMemo(() => {
    return {
      series: {
        pointStart: dateRange?.startDate?.toMillis(),
        pointInterval: resolution === "hourly" ? HOUR_MILIS : DAY_MILIS,
        pointWidth: 10,
        marker: {
          enabled: true,
        },
      },
    };
  }, [dateRange?.startDate, resolution]);

  return (
    <BaseCard title="Average Indoor Climate">
      <Box width="100%" height={700}>
        <HighchartsBase
          chart={{ height: 680, type: "line" }}
          plotOptions={plotOptions}
          series={series}
          xAxis={{
            type: "datetime",
            labelFormatType: resolution === "hourly" ? "dateOrTime" : "date",
          }}
          yAxis={yAxis}
          tooltip={{
            shared: true,
            split: true,
            crosshairs: true,
          }}
          disableBoost
        />
      </Box>
    </BaseCard>
  );
};

export default IndoorClimateGraph;
