import { queryOptions } from "@tanstack/react-query";

import { MapCoords } from "@core/types/common";
import { AvailableApis } from "@stores/api.store";

export const getWeatherOptions = (
  apis: AvailableApis,
  coordinates: MapCoords,
  startDate: string | null,
  endDate: string | null
) =>
  queryOptions({
    queryKey: ["weather", coordinates, startDate, endDate],
    queryFn: () => apis.weatherApi.getWeatherData(coordinates, startDate, endDate),
    enabled: !!coordinates.lat && !!coordinates.lon && !!startDate && !!endDate,
  });
