import { useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "@tanstack/react-router";

import { useNetworkStore } from "@core";
import IndoorClimatePage from "@components/IndoorClimate/IndoorClimatePage";
import { FontAwesomeSvgIcon, SectionSelector } from "@shared";

import { usePropertyStore } from "../../stores/property.store";
import { PropertyDetailEnergyData } from "./PropertyDetail.energy";
import { PropertyDetailMaintenance } from "./PropertyDetail.maintenance";
import { PropertyDetailOptimization } from "./PropertyDetail.optimization";
import { PropertyDetailOverview } from "./PropertyDetail.overview";

function UnderConstruction() {
  return (
    <Box pt={5} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h3">Under Construction</Typography>
    </Box>
  );
}

export function PropertyDetailModule() {
  const propertyId = useNetworkStore((s) => s.selectedSubstationId);
  const fetch = usePropertyStore((s) => s.fetch);

  useEffect(() => {
    if (!propertyId) return;
    void fetch(propertyId);
  }, [propertyId, fetch]);

  return (
    <>
      {/* Top Left, we just force a back button */}
      <IconButton
        component={Link}
        to="/app"
        size="large"
        sx={{
          top: 10,
          left: 10,
          position: "absolute",
          color: "white",
          zIndex: 100,
          cursor: "pointer",
        }}
      >
        <FontAwesomeSvgIcon icon={faChevronLeft} />
      </IconButton>
      <SectionSelector
        centered
        selected="indoor-climate"
        sections={[
          { title: "overview", id: "overview", component: PropertyDetailOverview, disabled: true },
          { title: "insights", id: "insights", component: UnderConstruction, disabled: true },
          {
            title: "indoor climate",
            id: "indoor-climate",
            component: IndoorClimatePage,
          },
          {
            title: "energy consumption",
            id: "energy_consumption",
            component: PropertyDetailEnergyData,
          },
          {
            title: "optimization",
            id: "optimization",
            component: PropertyDetailOptimization,
          },
          {
            title: "maintenance",
            id: "maintenance",
            component: PropertyDetailMaintenance,
            disabled: true,
          },
        ]}
      />
    </>
  );
}
