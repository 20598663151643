/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { useEffect } from "react";
import {
  Box,
  CircularProgress,
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import type { ToSubOptionsProps } from "@tanstack/react-router/dist/esm/link";
import { APIProvider } from "@vis.gl/react-google-maps";
import * as Highcharts from "highcharts/highstock";
import { DateTime } from "luxon";

import { queryClient } from "@core";

import "@core/fonts";
import "@core/i18n";

import { GMAPS_KEY } from "@config";
import axiosClient, { CustomAxiosDefaults } from "@core/apis/apiClient";
import authStore from "@core/stores/auth.store";
import apiStore from "@stores/api.store";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { defaultTheme } from "./theme";

const domain = import.meta.env.REACT_APP_PROPAI_AUTH0_DOMAIN;
const clientId = import.meta.env.REACT_APP_PROPAI_AUTH0_CLIENTID;
const audience = import.meta.env.REACT_APP_AUTH0_AUDIENCE;

Highcharts.setOptions({
  time: {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    timezone: DateTime.local().zoneName!,
    useUTC: false, // if you enable it, there are no daylight time saving changes.
  },
});

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPendingComponent: () => (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <CircularProgress />
    </Box>
  ),
  defaultErrorComponent: ({ error }) => (
    // eslint-disable-next-line i18next/no-literal-string
    <div className={`p-2 text-2xl`}>Error: {error.message}</div>
  ),
  context: {
    auth: undefined, // We'll inject this when we render
    apiStore: undefined,
    queryClient,
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
  interface StaticDataRouteOption {
    pageTitle?: string;
    pageIcon?: IconDefinition;
  }
} 

const RouterComponent = () => {
  const auth = useAuth0();
  useEffect(() => {
    const getToken = async () => {
      authStore.set.token(await auth.getAccessTokenSilently());
      apiStore.set.initializeApis(authStore);
    };

    if (!auth.isLoading) {
      getToken().catch(console.error);
    }
  }, [auth]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(defaultTheme)}>
          <APIProvider apiKey={GMAPS_KEY}>
            <CssBaseline />
            {!auth.isLoading ? (
              <RouterProvider
                router={router}
                context={{
                  auth,
                  queryClient,
                  apiStore,
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </APIProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};

export const App = () => {

  const onRedirectCallback = async (appState: { returnTo: string }) => {
    console.log("onRedirectCallback", appState); 

    const navigationParams = {
      to: "/app",
      params: {}
    }

    // TODO: Split this into a separate function
    if (appState?.returnTo && appState.returnTo.includes("activate-account")) {
      navigationParams.to = "/app/activate-account/$activationCode";
      navigationParams.params = {
        activationCode: appState.returnTo.split("/").pop()
      };
    }
    router.navigate(navigationParams);
  };
  
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      // @ts-expect-error ignore
      onRedirectCallback={onRedirectCallback}
    >
      <QueryClientProvider client={queryClient}>
        <RouterComponent />
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </QueryClientProvider>
    </Auth0Provider>
  );
};
