import { useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useAuth0, User } from "@auth0/auth0-react";
import { FontawesomeObject } from "@fortawesome/fontawesome-svg-core";
import { faMoon, faMugSaucer, faSun, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { DateTime } from "luxon";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import {
  getFlexStationsPerSubstationOptions,
  getSensorsPerSubstationOptions,
  getSubstationsOptions,
} from "@apis/resource.queries";
import { logger as baseLogger, useNetworkStore } from "@core";
import { PropertyDetail } from "@modules/PropertyDetail";
import apiStore from "@stores/api.store";
import useDashboardStore from "@stores/dashboard.store";
import { FontAwesomeSvgIcon, HeadingBar } from "@shared";
import { useDefaultTableTheme } from "@shared/ui/analytics/tables/Table/utils";

const logger = baseLogger.getSubLogger({ name: "DashboardModule" });

export default function DashboardModule() {
  // Server State
  const { user } = useAuth0();
  const { data: substations } = useQuery(getSubstationsOptions(apiStore.get.apis()));

  const { data: substationFlexStations } = useQueries({
    queries: (substations || []).map((substation) =>
      getFlexStationsPerSubstationOptions(apiStore.get.apis(), substation.uid)
    ),
    combine: (results) => {
      const flexStatations = results.map((result) => {
        if (result.isSuccess) {
          return result.data;
        }
        return [];
      });
      return {
        data: flexStatations,
        isSuccess: results.every((result) => result.isSuccess),
      };
    },
  });

  // Client State
  const { ready } = useDashboardStore();

  const tableData = useMemo(() => {
    const substationsWithSensors = substations?.map((substation, index) => ({
      ...substation,
      sensors:
        substationFlexStations[index]?.flatMap((flexStation) => flexStation.sensors).length || 0,
      flexStations: substationFlexStations[index]
        .map((flexStation) => flexStation.external_id)
        .join(", "),
    }));

    return substationsWithSensors || [];
  }, [substations, substationFlexStations]);

  logger.debug("RENDER Dashboard", {
    user,
  });

  const unwrapUsername = (user: User | undefined) => {
    if (!user) return "";
    if (user?.nickname) {
      if (user.nickname.includes("@")) {
        return user.nickname.split("@")[0];
      }
      return user.nickname.split(".")[0];
    }
    if (user?.email) {
      return user.email.split("@")[0];
    }
    return "";
  };

  const getTimeOfDay: () => {
    icon: IconDefinition;
    greeting: string;
  } = () => {
    const now = DateTime.now();
    const hour = now.hour;

    if (hour < 12) {
      return {
        icon: faMugSaucer,
        greeting: "Good morning",
      };
    } else if (hour < 18) {
      return {
        icon: faSun,
        greeting: "Good afternoon",
      };
    } else {
      return {
        icon: faMoon,
        greeting: "Good evening",
      };
    }
  };

  const { icon, greeting } = getTimeOfDay();

  const table = useMaterialReactTable({
    ...useDefaultTableTheme({}, {}),
    columns: [
      {
        header: "Name",
        accessorKey: "name",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return (
            <Link
              to="/app/properties/$substationId"
              params={{ substationId: cell.row.original.uid }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        header: "Sensors",
        accessorKey: "sensors",
      },
      {
        header: "Flex Stations",
        accessorKey: "flexStations",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return <Typography>{value}</Typography>;
        },
      },
      {
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ cell }) => {
          return (
            <Link
              to="/app/properties/$substationId"
              params={{ substationId: cell.row.original.uid }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button variant="contained" color="secondary">
                View
              </Button>
            </Link>
          );
        },
      },
    ],
    data: tableData,
  });

  return (
    <Grid container direction="row" spacing={2} p={2}>
      <Grid item xs={12} pb={2}>
        <Grid container direction="row" alignItems="center" spacing={4} px={2}>
          <Grid item>
            <FontAwesomeSvgIcon icon={icon} sx={{ fontSize: 100 }} />
          </Grid>
          <Grid item>
            <Typography
              variant="h2"
              fontWeight={400}
              fontFamily="Roboto"
              pb={1}
              sx={{ textTransform: "capitalize", textDecoration: "underline" }}
            >
              {`${greeting}, ${unwrapUsername(user)}`}
            </Typography>
            <Typography variant="h6" fontWeight={400} fontFamily="Roboto">
              Start exploring your properties by clicking on the name of the property.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <HeadingBar title="All Properties" />
        <MaterialReactTable table={table} />
      </Grid>
    </Grid>
  );
}
