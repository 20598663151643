import { useMemo } from "react";
import { Grid } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { getMeterDataOptions } from "@apis/resource.queries";
import { getWeatherOptions } from "@apis/weather.queries";
import { UNIT } from "@config";
import { useNetworkStore } from "@core";
import { MeterDataComponent } from "@models/meter-data.model";
import apiStore from "@stores/api.store";
import { GraphContainer, HighchartsBase } from "@shared";

function HeatEnergyGraph({ meterData }: { meterData: any[] }) {
  const theme = useTheme();

  const optimizedData = useMemo(() => {
    // Mock this, replace with actual data later
    // We just take and modify the normal data for now
    return meterData?.map((point: [number, number]) => {
      const [timestamp, value] = point;
      return [timestamp, value * Math.random() * 0.5 + value * 0.5];
    });
  }, [meterData]);

  return (
    <HighchartsBase
      disableExporting={true}
      rangeSelector={{
        enabled: false,
      }}
      navigator={{
        enabled: false,
      }}
      {...{
        xAxis: {
          type: "datetime",
          tickInterval: 24 * 3600 * 1000,
          labels: {
            format: "{value:%d %b}",
          },
        },
        yAxis: {
          title: {
            text: "Heat Energy kW/h",
          },
        },
        series: [
          {
            name: "Heat energy (normal)",
            data: meterData,
            color: theme.palette.primary.light,
          },
          {
            name: "Heat energy (optimized)",
            data: optimizedData,
            color: theme.palette.secondary.light,
          },
        ],
        legend: {
          enabled: true,
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          xDateFormat: "%d %b %H:%M",
        },
      }}
    />
  );
}

function OutdoorGraph({
  meterData,
  dateRange,
}: {
  meterData: any[];
  dateRange: { start: DateTime; end: DateTime };
}) {
  const theme = useTheme();

  const { data: outdoorData, isLoading: outdoorDataLoading } = useQuery(
    getWeatherOptions(
      apiStore.get.apis(),
      { lat: 57.7089, lon: 11.9746 },
      dateRange.start.toISO(),
      dateRange.end.toISO()
    )
  );

  const formattedWeatherData = useMemo(() => {
    if (!outdoorData || outdoorData.length === 0) {
      console.log("No weather data available");
      return [];
    }

    return outdoorData.map((point: { datetime: string; value: number }) => {
      return [new Date(point.datetime).getTime(), point.value];
    });
  }, [outdoorData]);
  return (
    <HighchartsBase
      loading={outdoorDataLoading}
      {...{
        chart: {
          type: "line",
        },
        xAxis: {
          type: "datetime",
          tickInterval: 24 * 3600 * 1000,
        },
        yAxis: [
          {
            title: {
              text: "Heat Energy (kW/h)",
            },
            yUnit: UNIT.energy,
          },
          {
            softMin: -20,
            title: {
              text: "Outdoor Temperature (°C)",
            },
            yUnit: UNIT.temp,
          },
        ],
        series: [
          {
            name: "Outdoor Temperature",
            type: "line",
            data: formattedWeatherData,
            color: theme.palette.primary.light,
            yAxis: 1,
          },
          {
            name: "Heat Energy",
            type: "line",
            data: meterData,
            color: theme.palette.secondary.light,
            yAxis: 0,
          },
        ],
      }}
      legend={{ enabled: false }}
    />
  );
}

export function PropertyDetailOptimization() {
  const fetchedRange = useNetworkStore((s) => s.fetchedRange);
  const selectedSubstationId = useNetworkStore((s) => s.selectedSubstationId);

  const { data: rawMeterData, isLoading: meterDataLoading } = useQuery(
    getMeterDataOptions(
      apiStore.get.apis(),
      selectedSubstationId,
      ["heat_energy", "returntemp", "supplytemp"],
      fetchedRange.start,
      fetchedRange.end
    )
  );

  const formattedMeterData = useMemo(() => {
    if (!rawMeterData || rawMeterData.length === 0) {
      console.log("No meter data available");
      return [];
    }

    return rawMeterData.map((component: MeterDataComponent) => {
      const sortedData = [...component.data].sort(
        (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
      );

      return {
        uid: component.resource_uid,
        meter: component.meter_name,
        stage: component.stage_name,
        component: component.component_name,
        variant: component.variant_name,
        data: sortedData.map((point) => ({
          datetime: point.datetime,
          value: point.value,
          flags: [],
        })),
      };
    });
  }, [rawMeterData]);

  const formattedHeatEnergyData = useMemo(() => {
    const heatEnergyComponent = formattedMeterData.find(
      (component) => component.component === "heat_energy"
    );
    return (
      heatEnergyComponent?.data.map((point: { datetime: string; value: number }) => {
        return [new Date(point.datetime).getTime(), point.value];
      }) ?? []
    );
  }, [formattedMeterData]);

  return (
    <Box width="100%">
      <Grid container columnSpacing={1} my={2}>
        <Grid item xs={12}>
          <GraphContainer title="Heat Energy vs Outdoor Temperature">
            <OutdoorGraph meterData={formattedHeatEnergyData} dateRange={fetchedRange} />
          </GraphContainer>
          <GraphContainer title="Optimized Heat Energy Meter Data">
            <HeatEnergyGraph meterData={formattedHeatEnergyData} />
          </GraphContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
