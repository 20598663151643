/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { AvailableApis } from "@stores/api.store";

export const getSubstationsOptions = (apis: AvailableApis) =>
  queryOptions({
    queryKey: ["substations"],
    queryFn: () => apis.resourceApi.getSubstations(),
  });

export const getNetworksOptions = (apis: AvailableApis) =>
  queryOptions({
    queryKey: ["networks"],
    queryFn: () => apis.resourceApi.getNetworks(),
    enabled: !!apis.resourceApi,
  });

export const getSensorsPerSubstationOptions = (apis: AvailableApis, substationUid?: string) =>
  queryOptions({
    queryKey: ["sensors", substationUid],
    queryFn: () => apis.resourceApi.getSensorsBySubstation(substationUid),
    enabled: !!substationUid,
    select(data) {
      return data.flatMap((flexStation) => flexStation.sensors);
    },
  });

export const getFlexStationsPerSubstationOptions = (apis: AvailableApis, substationUid?: string) =>
  queryOptions({
    queryKey: ["sensors", substationUid],
    queryFn: () => apis.resourceApi.getSensorsBySubstation(substationUid),
    enabled: !!substationUid,
  });

export const getMeterDataOptions = (
  apis: AvailableApis,
  substationUID: string | undefined,
  components: string[] = ["heat_energy", "returntemp", "supplytemp"],
  fromDate?: DateTime,
  toDate?: DateTime,
  pageSize?: number,
  page?: number
) =>
  queryOptions({
    queryKey: [
      "substations",
      substationUID,
      "meter-data",
      components,
      fromDate,
      toDate,
      pageSize,
      page,
    ],
    queryFn: () =>
      apis.resourceApi.getMeterData(
        substationUID!,
        components,
        fromDate?.toISO(),
        toDate?.toISO(),
        pageSize,
        page
      ),
    enabled: !!substationUID,
  });

export const getSensorsDataPerSubstation = (
  apis: AvailableApis,
  substationUid?: string,
  sensorUid?: string,
  fromDate?: DateTime | null,
  toDate?: DateTime | null
) =>
  queryOptions({
    queryKey: ["sensors", substationUid, sensorUid, fromDate, toDate],
    queryFn: () =>
      apis.resourceApi.getSensorData(
        substationUid,
        sensorUid,
        fromDate!.toISO()!,
        toDate!.toISO()!
      ),
    enabled: !!substationUid && !!sensorUid && !!fromDate && !!toDate,
  });


export const acceptInviteOptions = (apis: AvailableApis, inviteToken: string) =>
  queryOptions({
    queryKey: ["accept-invite", inviteToken],
    queryFn: () => apis.resourceApi.acceptInvitation(inviteToken),
    enabled: !!inviteToken,
    retry: false,
  });
  