import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { faStairs } from "@fortawesome/pro-regular-svg-icons";
import { DateTime } from "luxon";

import { Sensor } from "@models/sensors.model";
import { BaseCard, FontAwesomeSvgIcon, HeadingBar, InfoCard } from "@shared";

type SensorOverviewProps = {
  sensors: Sensor[];
};

const SensorCard: React.FC<{ sensor: Sensor; targetTemperature: number }> = ({
  sensor,
  targetTemperature,
}) => {
  const lastReading = useMemo(() => {
    if (sensor.data && sensor.data.length > 0) {
      // Make sure its within the last hour
      const lastReading = sensor.data[sensor.data.length - 1];
      const lastTimestamp = DateTime.fromISO(lastReading.timestamp);
      const now = DateTime.now();
      if (lastTimestamp > now.minus({ hours: 1 })) {
        return lastReading;
      }
    }
    return null;
  }, [sensor.data]);

  const getBackroundColor = (value: number) => {
    const maxColor = "#dda0b0";
    const minColor = "#8aa4df";

    const range = 5;
    const min = targetTemperature - range;
    const max = targetTemperature + range;
    const ratio = (value - min) / (max - min);

    if (ratio < 0) return minColor;
    if (ratio > 1) return maxColor;
    // Interpolate between min and max color
    const r = Math.round(
      parseInt(minColor.slice(1, 3), 16) +
        ratio * (parseInt(maxColor.slice(1, 3), 16) - parseInt(minColor.slice(1, 3), 16))
    );
    const g = Math.round(
      parseInt(minColor.slice(3, 5), 16) +
        ratio * (parseInt(maxColor.slice(3, 5), 16) - parseInt(minColor.slice(3, 5), 16))
    );
    const b = Math.round(
      parseInt(minColor.slice(5, 7), 16) +
        ratio * (parseInt(maxColor.slice(5, 7), 16) - parseInt(minColor.slice(5, 7), 16))
    );
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  };

  return (
    <Box m={0.5} maxWidth={200} minWidth={200} borderRadius={2} overflow="hidden">
      {lastReading ? (
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Box
              sx={{
                backgroundColor: getBackroundColor(lastReading.values.living_air_temperature),
                // Just top left radius
                borderTopLeftRadius: "8px",
                border: "1px solid #004",
              }}
            >
              <Typography variant="body2" color="text.primary" textAlign="center" pt={1}>
                {lastReading.values.living_air_temperature?.toFixed(2)} °C
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                // Just top right radius
                borderTopRightRadius: "8px",
                border: "1px solid #004",
              }}
            >
              <Typography variant="body2" color="text.primary" textAlign="center" pt={1}>
                {lastReading.values.indoor_relative_humidity?.toFixed(2)} %
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                // Just bottom left and right radius
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                border: "1px solid #004",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
                textOverflow="ellipsis"
                maxWidth={200}
                overflow="hidden"
              >
                {sensor.external_id || sensor.location}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            // Just bottom left and right radius
            borderRadius: "8px",
            border: "1px solid #004",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            No recent data
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const SensorOverview: React.FC<SensorOverviewProps> = ({ sensors }) => {
  // Calculate average temperature and humidity
  const { avgTemp, avgHumidity } = sensors.reduce(
    (acc, sensor) => {
      const lastReading = sensor.data?.[sensor.data.length - 1];
      if (lastReading) {
        acc.avgTemp += lastReading.values.living_air_temperature || 0;
        acc.avgHumidity += lastReading.values.indoor_relative_humidity || 0;
        acc.count++;
      }
      return acc;
    },
    { avgTemp: 0, avgHumidity: 0, count: 0 }
  );

  const averageTemperature = avgTemp / (sensors.length || 1);
  const averageHumidity = avgHumidity / (sensors.length || 1);

  const sensorsPerFloor = useMemo(() => {
    const floorMap = new Map<string, Sensor[]>();
    sensors.forEach((sensor) => {
      const floor = sensor.floor || "N/A";
      if (!floorMap.has(floor)) {
        floorMap.set(floor, []);
      }
      floorMap.get(floor)?.push(sensor);
    });
    return Array.from(floorMap.entries()).reverse();
  }, [sensors]);

  const targetTemperature = 21; // Example target temperature

  return (
    <>
      <HeadingBar
        title="Sensor Overview"
        addons={[
          {
            text: "Data for the last hour",
          },
        ]}
      />
      <Grid container spacing={2} px={2}>
        {/* Info Cards */}
        <Grid container item spacing={2} flexDirection="column" xs={3}>
          <Grid item xs>
            <InfoCard
              title="Average Indoor Temperature"
              size="small"
              value={`${averageTemperature?.toFixed(2)} °C`}
            />
          </Grid>
          <Grid item xs>
            <InfoCard title="Average Indoor Humidity" value={`${averageHumidity?.toFixed(2)} %`} size="small" />
          </Grid>
        </Grid>

        {/* Heatmap Grid */}
        <Grid item xs>
          <BaseCard title="Individual Sensors">
            <Grid
              container
              spacing={2}
              px={2}
              py={4}
              flexGrow={1}
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {sensorsPerFloor.map(([floor, sensors]) => (
                <Grid
                  item
                  container
                  xs={12}
                  key={floor}
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  <Grid item xs={1}>
                    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                      <FontAwesomeSvgIcon icon={faStairs} />
                      <Typography variant="h6">{floor}</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={2}
                    xs
                    alignItems="center"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                  >
                    {sensors.map((sensor) => (
                      <Grid item key={sensor.uid}>
                        <SensorCard sensor={sensor} targetTemperature={targetTemperature} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </BaseCard>
        </Grid>
      </Grid>
    </>
  );
};

export default SensorOverview;
