/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosDefaults, CreateAxiosDefaults } from "axios";

const BASE_URL = import.meta.env?.["REACT_APP_PROPAI_URL"];

type CustomAxiosClientConfig = {
  shouldCamelCaseResponse: boolean;
} & CreateAxiosDefaults;

export type CustomAxiosDefaults = {
  shouldCamelCaseResponse?: boolean;
} & AxiosDefaults<any>;

export const customAxiosDefaults: CustomAxiosClientConfig = {
  shouldCamelCaseResponse: true,
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
