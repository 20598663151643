import { AxiosInstance } from "axios";

import { WeatherData } from "@core";
import { MapCoords } from "@core/types/common";

export class WeatherApi {
  client: AxiosInstance;
  constructor(client: AxiosInstance) {
    this.client = client;
  }

  // This could be shared, but I think this might change in the near future
  async getWeatherData(
    coordinates: MapCoords | undefined,
    start_time: string | undefined | null,
    end_time: string | undefined | null
  ): Promise<WeatherData[]> {
    if (!coordinates || !coordinates.lat || !coordinates.lon) {
      throw new Error("Network coordinates are required");
    }
    if (!start_time || !end_time) {
      throw new Error("Start and end time are required");
    }
    // https://wps.energypredict.se/weather-data/lon/12.4372761/lat/57.7036364?date_min=2023-06-30T22%3A00%3A00&date_max=2024-07-17T21%3A59%3A59&metrics=t
    return this.client
      .get(`/weather-data/lon/${coordinates.lon}/lat/${coordinates.lat}`, {
        baseURL: import.meta.env?.REACT_APP_WEATHER_URL,
        headers: {
          Authorization: `WDSL wdsl=NEGEM6HKDM8PQENLOE9OUK3B614A7TZX`,
        },
        params: {
          date_min: start_time,
          date_max: end_time,
          metrics: "t",
        },
      })
      .then((res) => res.data[0].data);
  }
}
