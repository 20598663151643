import { ReactNode, useState } from "react";
import { Box, Collapse, Typography } from "@mui/material";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ControlPanelItemHeaderProps = {
  title: string;
  isOpen: boolean;
  onToggle: (val: boolean) => void;
};

const ControlPanelItemHeader = ({ title = "", isOpen, onToggle }: ControlPanelItemHeaderProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onToggle(!isOpen);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1.5,
        borderBottom: isOpen ? "1px solid #e0e0e0" : "none",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
      <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
    </Box>
  );
};

type ControlPanelItemProps = {
  title: string;
  children: ReactNode;
};

const ControlPanelItem = ({ title, children }: ControlPanelItemProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <ControlPanelItemHeader title={title} isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} />
      <Collapse in={isOpen} sx={{ px: 1 }}>
        {children}
      </Collapse>
    </div>
  );
};

export default ControlPanelItem;
