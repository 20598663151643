import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Grid, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useQueries, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useStore } from "zustand";

import {
  getSensorsDataPerSubstation,
  getSensorsPerSubstationOptions,
} from "@apis/resource.queries";
import { useNetworkStore } from "@core";
import { ControlPanel } from "@components/EnergyConsumption";
import { Resolution } from "@components/EnergyConsumption/ControlPanel";
import { Sensor, SensorReading } from "@models/sensors.model";
import apiStore from "@stores/api.store";

import ControlPanelItem from "./ControlPanelItem";
import IndoorClimateGraph from "./IndoorClimateGraph";
import SensorCheckbox from "./SensorCheckbox";
import SensorOverview from "./SensorOverview";

const averageSensorItem = (sensors: Sensor[]) => ({
  uid: "average-sensor",
  external_id: `Average (${sensors.length} sensors)`,
  sensor_type_technical_name: "average_sensor",
});

const calculateAverageSensorData = (realSensorData: SensorReading[][]): SensorReading[] => {
  const sensorDataByHour: Record<
    string,
    { humiditySum: number; tempSum: number; humidityCount: number; tempCount: number }
  > = {};

  realSensorData.forEach((sensorData) => {
    sensorData.forEach((data) => {
      const hourTimestamp = DateTime.fromISO(data.timestamp).startOf("hour").toISO() || "";

      if (!sensorDataByHour[hourTimestamp]) {
        sensorDataByHour[hourTimestamp] = {
          humiditySum: 0,
          tempSum: 0,
          humidityCount: 0,
          tempCount: 0,
        };
      }

      const hourData = sensorDataByHour[hourTimestamp];

      if (
        data.values.indoor_relative_humidity !== null &&
        data.values.indoor_relative_humidity !== undefined
      ) {
        hourData.humiditySum += data.values.indoor_relative_humidity;
        hourData.humidityCount += 1;
      }

      if (
        data.values.living_air_temperature !== null &&
        data.values.living_air_temperature !== undefined
      ) {
        hourData.tempSum += data.values.living_air_temperature;
        hourData.tempCount += 1;
      }
    });
  });

  return Object.entries(sensorDataByHour).map(
    ([timestamp, { humiditySum, tempSum, humidityCount, tempCount }]) => ({
      timestamp,
      values: {
        indoor_relative_humidity: humidityCount > 0 ? humiditySum / humidityCount : 0,
        living_air_temperature: tempCount > 0 ? tempSum / tempCount : 0,
      },
    })
  );
};

const IndoorClimatePage = () => {
  // Client State
  const selectedSubstationId = useStore(useNetworkStore, (s) => s.selectedSubstationId);

  const [visibleSeries, setVisibleSeries] = useState({
    temp: true,
    humid: false,
  });

  const { t } = useTranslation(["_action"]);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [resolution, setResolution] = useState<Resolution>("hourly");
  const [dateRange, setDateRange] = useState({
    startDate: DateTime.now().minus({ days: 7 }).startOf("day"),
    endDate: DateTime.now().plus({ hour: 1 }).startOf("hour"),
  });
  const [selectedSensors, setSelectedSensors] = useState<string[]>([]); // Track selected sensors by UID

  // Server State
  const { data: substationSensors, isLoading: fetchingSensors } = useQuery(
    getSensorsPerSubstationOptions(apiStore.get.apis(), selectedSubstationId)
  );

  const fullSensorList = useMemo(() => {
    if (!substationSensors) return [];
    const sensors = substationSensors
      .filter((sensor) => sensor.sensor_type_technical_name === "living_air_temperature")
      .map((sensor) => ({
        ...sensor,
        uid: sensor.uid,
      }));
    return [averageSensorItem(sensors), ...sensors];
  }, [substationSensors]);

  const { data: realSensorData, isSuccess: fetchedSensorsData } = useQueries({
    queries: (substationSensors ?? [])
      .filter((sensor) => sensor.uid !== "average-sensor")
      .filter((sensor) => sensor.sensor_type_technical_name === "living_air_temperature")
      .map((sensor) =>
        getSensorsDataPerSubstation(
          apiStore.get.apis(),
          selectedSubstationId,
          sensor.uid,
          dateRange.startDate,
          dateRange.endDate
        )
      ),
    combine: (results) => {
      const sensors = results.map((result) => {
        if (result.isSuccess) {
          return result.data;
        }
        return [];
      });
      return {
        data: sensors,
        isSuccess: results.every((result) => result.isSuccess),
      };
    },
  });

  const sensorsWithData: Sensor[] = useMemo(() => {
    // calculate average sensor data if available
    if (fetchedSensorsData && realSensorData.length > 0 && substationSensors) {
      // Assign data to each sensor
      const sensorsWithData = realSensorData.map((sensorData, index) => ({
        ...substationSensors[index],
        data: sensorData,
      }));

      // Calculate average values

      const averageSensorData = calculateAverageSensorData(realSensorData);

      // Select avg by default
      setSelectedSensors(["average-sensor"]);
      return [
        {
          uid: "average-sensor",
          external_id: `Average (${realSensorData.length} sensors)`,
          sensor_type_technical_name: "average_sensor",
          data: averageSensorData,
        },
        ...sensorsWithData,
      ] as Sensor[];
    }

    return [];
  }, [realSensorData, fetchedSensorsData, substationSensors]);

  const handlePanelToggle = (isOpen: boolean) => {
    setIsPanelOpen(isOpen);
  };

  const handleSelectedAll = () => {
    setSelectedSensors(fullSensorList.map((sensor) => sensor.uid));
  };

  const handleDeselectAll = () => {
    setSelectedSensors([]);
  };

  const handleResolutionChange = (newResolution: Resolution) => {
    setResolution(newResolution);
  };

  const handleDateRangeChange = (startDate: DateTime | null, endDate: DateTime | null) => {
    if (!startDate || !endDate) return;
    setDateRange({ startDate, endDate });
  };

  return (
    <Box width="100%">
      {!fetchedSensorsData && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
          color="primary"
        />
      )}
      <Box sx={{ display: "flex", width: "100%" }}>
        {/* Control Panel Sidebar */}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            height: `calc(100vh - 200px)`,
            flexShrink: 0,
            zIndex: 10,
            alignSelf: "flex-start",
            bgcolor: "background.paper",
          }}
        >
          <ControlPanel
            isOpen={isPanelOpen}
            onToggle={handlePanelToggle}
            onResolutionChange={handleResolutionChange}
            onDateRangeChange={handleDateRangeChange}
          >
            {/* Sensor content */}
            {fetchingSensors && !fetchedSensorsData ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100px",
                }}
              >
                <CircularProgress size={32} />
              </Box>
            ) : (
              <>
                <ControlPanelItem title="Sensor Display">
                  <Box py={2} display="flex" justifyContent="space-between">
                    <Button
                      variant="outlined"
                      sx={{ borderRadius: "20px", textTransform: "none" }}
                      onClick={handleSelectedAll}
                      disabled={!!(selectedSensors.length === fullSensorList.length)}
                    >
                      {t("action_select_all")}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ borderRadius: "20px", textTransform: "none" }}
                      disabled={!selectedSensors.length}
                      onClick={handleDeselectAll}
                    >
                      {t("action_deselect_all")}
                    </Button>

                    {/*TODO: Add functionality */}
                    <SearchOutlined />
                  </Box>
                  {/* Sensor checkbox list */}
                  <Box sx={{ maxHeight: 400, overflow: "auto", scrollbarWidth: "thin" }}>
                    {fullSensorList?.map((sensor) => (
                      <SensorCheckbox
                        key={sensor.uid}
                        sensor={sensor}
                        selectedSensors={selectedSensors}
                        setSelectedSensors={setSelectedSensors}
                      />
                    ))}
                  </Box>
                </ControlPanelItem>
                <ControlPanelItem title="Graph Options">
                  <Box display="flex" justifyContent="space-between" py={2}>
                    <Button
                      variant={visibleSeries.temp ? "outlined" : "contained"}
                      sx={{ borderRadius: "20px", textTransform: "none" }}
                      onClick={() =>
                        setVisibleSeries({ ...visibleSeries, temp: !visibleSeries.temp })
                      }
                    >
                      {visibleSeries.temp ? "Hide Temperature" : "Show Temperature"}
                    </Button>
                    <Button
                      variant={visibleSeries.humid ? "outlined" : "contained"}
                      sx={{ borderRadius: "20px", textTransform: "none" }}
                      onClick={() =>
                        setVisibleSeries({ ...visibleSeries, humid: !visibleSeries.humid })
                      }
                    >
                      {visibleSeries.humid ? "Hide Humidity" : "Show Humidity"}
                    </Button>
                  </Box>
                </ControlPanelItem>
              </>
            )}
          </ControlPanel>
        </Box>
        {/* Graph Content */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            paddingLeft: "16px",
            transition: "padding 0.3s ease",
          }}
          py={2}
        >
          <Grid container spacing={2} pb={2}>
            <Grid item xs={12}>
              <SensorOverview sensors={sensorsWithData} />
            </Grid>
            <Grid item xs={12}>
              {/* Indoor Climate Graph */}
              {selectedSensors.length > 0 && fetchedSensorsData && (
                <IndoorClimateGraph
                  selectedSensors={selectedSensors}
                  sensors={sensorsWithData}
                  resolution={resolution}
                  dateRange={dateRange}
                  visibleSeries={visibleSeries}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default IndoorClimatePage;
