/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";

import { logger as baseLogger } from "@core/logger";
import { Network, Substation } from "@core/models";
import { MeterDataComponent } from "@models/meter-data.model";
import { FlexStation, Sensor } from "@models/sensors.model";

const logger = baseLogger.getSubLogger({ name: "resource.api" });

type MdslResponse = {
  data:
    | {
        collection: any[];
      }
    | any;
  error?: {
    message: string;
  };
};

// TODO: Rename to PropAI API
export class ResourceApi {
  client: AxiosInstance;
  constructor(client: AxiosInstance) {
    logger.debug("ResourceApi constructor");
    this.client = client;
  }

  async getNetworks(): Promise<Network[]> {
    return this.client.get("/v1/networks").then((response: MdslResponse) => {
      return response.data.data.collection;
    });
  }

  async getSubstations(): Promise<Substation[]> {
    return this.client.get("/v1/substations").then((response: MdslResponse) => {
      return response.data.data.collection;
    });
  }

  async getSensorsBySubstation(substationUid?: string): Promise<FlexStation[]> {
    return this.client
      .get(`/v1/substations/${substationUid}/sensors`)
      .then((response: MdslResponse) => {
        return response.data.data?.flex_stations;
      });
  }

  async getSensorData(
    substationUid?: string,
    sensorUid?: string,
    fromDate?: string,
    toDate?: string
  ): Promise<any[]> {
    return this.client
      .get(`/v1/substations/${substationUid}/sensors/${sensorUid}/readings`, {
        params: {
          "datetime[ge]": fromDate ?? "",
          "datetime[it]": toDate ?? "",
        },
      })
      .then((response) => {
        return response.data.data.collection;
      });
  }

  async getMeterData(
    substationUID: string,
    components: string[] = ["heat_energy", "returntemp", "supplytemp"],
    fromDate?: string | null,
    toDate?: string | null,
    pageSize?: number,
    page?: number
  ): Promise<MeterDataComponent[]> {
    const params = new URLSearchParams();

    // Add components parameter
    if (components.length > 0) {
      params.append("component[in]", components.join(","));
    }

    // Add date range parameters if provided
    if (fromDate) {
      params.append("datetime[ge]", fromDate);
    }

    if (toDate) {
      params.append("datetime[lt]", toDate);
    }

    // Add pagination
    if (pageSize) {
      params.append("page_size", pageSize.toString());
    }

    if (page) {
      params.append("page", page.toString());
    }

    // Build URL
    const url = `/v1/meter-data/${substationUID}${params.toString() ? `?${params.toString()}` : ""}`;

    logger.debug("getMeterData request", {
      url,
      substationUID,
      components,
      fromDate,
      toDate,
    });

    return this.client
      .get(url)
      .then((response: MdslResponse) => {
        return response.data.data.collection;
      })
      .catch((error) => {
        logger.error("getMeterData error", { error });
        throw new Error(error.response?.data?.error?.message || "Unknown error");
      });
  }

  
  async acceptInvitation(invitationCode: string): Promise<{ organization_uid: string, organization_name: string }> {
    return this.client
      .get("/v1/admin/invitations/accept", {
        params: { token: invitationCode },
      })
      .then((response) => {
        logger.debug("acceptInvitation success", { token: invitationCode });
        return response.data.data;
      })
      .catch((error) => {
        logger.error("acceptInvitation error", { error });
        throw new Error(error.response?.data?.error || "Unknown error");
      });
  }
}
